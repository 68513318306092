import React, { FunctionComponent } from "react";
import { Col, Row } from "react-bootstrap";
import {
  BaseDropdown,
  BaseFieldSet,
  BaseForm,
  BaseSelect,
  FilterOperator,
} from "react-epharma-components";
import { UseSearch } from "./useSearch";

const Search: FunctionComponent = (props) => {
  const {
    form,
    clientType,
    inputsRef,
    industry,
    client,
    period,
    optionClientType,
    urlIndustry,
    urlPeriod,
    urlClient,
    handleChangeUrlCliente,
    optionsDefault,
    setIndustryId,
  } = UseSearch();

  return (
    <BaseForm ref={form} refs={inputsRef} {...props}>
      <BaseFieldSet legend="Filtros">
        <Row>
          <Col md="12">
            <BaseSelect
              label="Tipo de cliente"
              name="clientType"
              options={optionClientType}
              ref={clientType}
              filterOperator={FilterOperator.Equals}
              required
              onChange={(event) => handleChangeUrlCliente(event.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <BaseDropdown
              name="IndustryId"
              label="Indústria"
              labelProperty="tradeName"
              valueProperty="id"
              endpoint={urlIndustry}
              ref={industry}
              required
              onSelect={({ value }) => {
                setIndustryId(value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <BaseDropdown
              label="Período"
              name="id"
              labelProperty="period"
              valueProperty="id"
              endpoint={urlPeriod}
              ref={period}
              disabled
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <BaseDropdown
              label="Cliente"
              name="client"
              labelProperty="tradeName"
              valueProperty="id"
              endpoint={urlClient}
              ref={client}
              disabled
              required
              joinOptions
              options={optionsDefault}
            />
          </Col>
        </Row>
      </BaseFieldSet>
    </BaseForm>
  );
};
export default Search;
