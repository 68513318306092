import { useState } from "react";
import {
  IAdditionalButtons,
  ICrud,
  ITableColumn,
} from "react-epharma-components";
import { ExportTypes } from "react-epharma-components/lib/components/export";
import { toast } from "react-toastify";
import { RowSelected } from "../../shared/types";

export const usePanel = () => {
  const defaultConfig = { sortable: false };
  const [showModal, setShowModal] = useState<boolean>(false);
  const [rowSelected, setRowSelected] = useState<RowSelected[]>([]);
  const [showModalWarning, setShowModalWarning] = useState<boolean>(true);

  const url = `${window.drugReplacementService.base}${window.drugReplacementService.preValidadao}`;
  const additionalButtons: IAdditionalButtons[] = [
    {
      name: "Disponibilizar",
      disabled: false,
      permissionId: "#DISPONIBILIZAR",
      onClick: () => {
        if (rowSelected.length > 0) handleDisponibilizar();
        else toast.error("Selecione ao menos um registro para essa ação.");
      },
      icon: { iconName: "face-confused", prefix: "fab" },
    },
  ];

  const columns: ITableColumn[] = [
    {
      identifier: true,
      name: "id",
      displayName: "Id",
      width: "10%",
      ...defaultConfig,
    },
    {
      name: "codIndustria",
      displayName: "Indústria",
      width: "10%",
      ...defaultConfig,
    },
    {
      name: "codCliente",
      displayName: "Cód. Cliente",
      width: "20%",
      ...defaultConfig,
    },
    {
      name: "cliente",
      displayName: "Cliente",
      width: "20%",
      ...defaultConfig,
    },
    {
      name: "tipoCliente",
      displayName: "Tipo de Cliente",
      width: "20%",
      ...defaultConfig,
    },
    {
      name: "periodo",
      displayName: "Período",
      width: "20%",
      ...defaultConfig,
    },
    {
      name: "quantidade",
      displayName: "Quantidade",
      width: "20%",
      ...defaultConfig,
    },
    {
      name: "valorTotal",
      displayName: "Valor Total",
      width: "20%",
      ...defaultConfig,
    },
  ];

  const crud: ICrud = {
    exportGrid: {
      show: true,
      types: [ExportTypes.csv, ExportTypes.txt],
    },
    additionalButtons: additionalButtons,
  };

  const handleDisponibilizar = () => {
    setShowModal(true);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const defineTypeClient = (clinetType: string) => {
    if (clinetType === "Rede") return "R";
    else return "D";
  };

  const handleSelected = (row: any) => {
    const ids: RowSelected[] = row.map((e: any) => ({
      id: e.id,
      clientType: defineTypeClient(e.tipoCliente),
    }));
    setRowSelected(ids);
  };

  return {
    url,
    crud,
    columns,
    showModal,
    handleCancel,
    handleSelected,
    rowSelected,
    showModalWarning,
    setShowModalWarning,
  };
};
