import React from "react";
import { BaseGrid, BaseScreen, BaseToolbar } from "react-epharma-components";
import { Available } from "../Available";
import { usePanel } from "./usePanel";
import Search from "../Search";
import { Alert, Modal } from "react-bootstrap";
export const Panel = ({ props }: any) => {
  const {
    url,
    crud,
    columns,
    showModal,
    handleCancel,
    handleSelected,
    rowSelected,
    setShowModalWarning,
    showModalWarning,
  } = usePanel();
  return (
    <BaseScreen title="">
      <Modal
        backdrop={true}
        show={showModalWarning}
        onHide={() => setShowModalWarning(false)}
      >
        <Modal.Header>
          <Modal.Title>Alerta</Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => setShowModalWarning(false)}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning">
            Realize uma pesquisa para visualizar os fechamentos pendentes
          </Alert>
        </Modal.Body>
      </Modal>
      <BaseGrid
        {...props}
        name="Pré Fechamentos"
        baseEndpoint={url}
        load={false}
        toolbar={
          <BaseToolbar
            crud={crud}
            search={{
              show: true,
              form: <Search />,
            }}
          ></BaseToolbar>
        }
        {...props}
        columns={columns}
        onSelect={(data) => {
          handleSelected(data);
        }}
      />
      <Available
        show={showModal}
        handleCancel={handleCancel}
        rowSelected={rowSelected}
      />
    </BaseScreen>
  );
};
